import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { breakpoints, ModalBackground, Button, useShoppingCart, useAuth, hexToRGBA } from '@ecommerce/shared'
import Layout from '../components/Layout'
import { PgPageProps } from '../types/PgPages'
import TrackingWidget from '../components/TrackingWidget'
import { Icon } from '../components/Icon/Icon'
import { sendPageViewEvent } from '../utils/events'

const ModalWrapper = styled.div`
  .modal-background {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-inner {
    width: 382px;
    background: ${({ theme }) => theme.colors.white};
    margin: 0 auto;
    box-shadow: 0 8px 16px ${({ theme }) => hexToRGBA(theme.colors.black20, 0.1)};
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 29px 16px 31px 16px;
    color: ${({ theme }) => theme.colors.black};

    &-close-btn {
      display: flex;
      width: 100%;
      justify-content: end;
      margin-bottom: 17px;

      svg {
        cursor: pointer;
        fill: ${({ theme }) => theme.colors.black};
      }
    }

    .content-modal {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-size: 17px;
        line-height: 22px;
        text-align: center;
        margin: 0 0 4px;

        .bold {
          font-weight: bold;
        }
      }

      img {
        margin-bottom: 24px;
      }

      .btn-close-button {
        min-width: 200px;
        margin: 0 auto;
      }
    }

    @media screen and (${breakpoints.tabletPortrait.min}) {
      padding: 34px 24px 47px 24px;
    }
  }
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.background.body};

  .inner {
    padding: 45px 20px 20px;
    text-align: center;
    min-height: calc(100vh - 265px);
    max-width: 600px;
    margin: 0 auto;

    h1 {
      font-size: 18px;
      border-bottom: 2px solid ${({ theme }) => theme.colors.grey};
      padding-bottom: 15px;
      max-width: 330px;
      margin: auto;
    }

    .description {
      margin-top: 32px;
      font-size: 16px;
      line-height: 22px;
    }

    .content {
      max-width: 500px;
      margin: 0 auto;
    }

    .help-text {
      all: unset;
      display: flex;
      align-items: center;
      font-size: 13px;
      padding: 0 17px;
      cursor: pointer;
      margin: 12px auto 0;

      .icon {
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    background: ${({ theme }) => theme.colors.background.bodyAlternative};
    padding: 70px 140px 50px;

    .inner {
      background: ${({ theme }) => theme.colors.background.body};
      max-width: 1200px;
      padding: 35px 40px 40px;
      border-radius: ${({ theme }) => theme.borderRadius};
      box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};

      h1 {
        max-width: 800px;
        font-size: 24px;
        padding-bottom: 35px;
      }

      .description {
        max-width: 400px;
        margin: 32px auto 0;
        font-size: 18px;
      }
    }
  }
`

const TrackingPage = ({ pageContext: { productCategories } }: PgPageProps) => {
  const [showModal, setShowModal] = useState(false)

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Tracking' })
  }, [])

  return (
    <Layout categories={productCategories} title="Sigue tu pedido">
      {showModal && (
        <ModalWrapper>
          <ModalBackground className="modal-background">
            <div className="modal-inner">
              <div className="modal-inner-close-btn">
                <Icon
                  data-testid="establishment-modal-close-btn-one"
                  iconId="close"
                  size="24"
                  data-test="close-icon"
                  onClick={() => setShowModal(false)}
                />
              </div>
              <div className="content-modal">
                <p>
                  <span>El </span>
                  <span className="bold">Número de pedido </span>
                  <span>aparece en tu correo con el detalle de tu compra.</span>
                </p>
                <img
                  src="https://images.ctfassets.net/16npdkkoi5mj/5jWfLDu7a9vJ2bjmQ7JcFt/72d8872798e1375e77a040e3b99c21d8/pedido-realizado-ilustracion.svg"
                  alt="tracking-number"
                  width="333.5"
                  height="425"
                />
                <Button className="btn-close-button" btnType="secondary" onClick={() => setShowModal(false)}>
                  Cerrar
                </Button>
              </div>
            </div>
          </ModalBackground>
        </ModalWrapper>
      )}
      <Wrapper>
        <div className="inner">
          <h1>Sigue tu pedido</h1>
          <p className="description">Ingresa con tu número de pedido que enviamos a tu correo</p>
          <div className="content">
            <button type="button" className="help-text" onClick={() => setShowModal(true)}>
              <span className="icon">
                <Icon iconId="help-fill" />
              </span>
              &nbsp;¿Como encontrar mi Nº de pedido?
            </button>
            <TrackingWidget />
          </div>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default TrackingPage
